.form{
    width: 100%;
    display: table;
    margin-left: 20;
    margin-right: 10;
    background-color:  rgba(255, 255, 255, 0);
    padding: 6px;
    border-radius: 5px;
  }

  .col-50 {
    -ms-flex: 5%; /* IE10 */
    flex: 5%;
  }