
.card {
  width: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid rgb(255, 255, 255);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  opacity: 1;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.box {
  width: auto;
  overflow: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box div {
  width: auto;
  height: auto;
}

.card-container {
  width: 100%;
  height: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid rgb(255, 255, 255);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  opacity: 1;
}

.card-container:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container {
  padding: 2px 16px;
}

.header {
  width: 100%;
  height: 40px;
  background: #ecf2f9 0% 0% no-repeat padding-box;
  border-radius: 8px 8px 0px 0px;
}

.header h1 {
  text-align: center;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  color: #4d4f5c;
}

.card-table {
  word-break: break-all;
}
