.form_block {
  width: 400px;
  display: table;
  margin-top: 20%;
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(170, 170, 170);
  padding: 6px;
  border-radius: 5px;
}
.form_block > #title {
  background: #3d8577;
  padding: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.2);
}
.form_block > .body {
  background: #ffffff;
  padding: 40px;
  display: table;
}

input[type="text"],
input[type="password"] {
  height: 35px;
  width: 100%;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 4px 5px;
  background: #fff;
  border: 1px solid #0e0b0b;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.loginBtn {
  height: 35px;
  box-sizing: border-box;
  padding: 4px 10px;
  background: #f5f4ea;
  border: 1px solid #d0d0d0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  cursor: pointer;
}
.loginBtn:hover {
  background: #c3c3ba;
}

.registerBtn {
  float: right;
  background: #3d8577;
  color: #fff;
  height: 35px;
  box-sizing: border-box;
  padding: 2px 10px;
  border: 1px solid #d0d0d0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  cursor: pointer;
  margin-top: -35px;
}
.registerBtn:hover {
  background: #2d6358;
}

.shopping-cart {
  width: 750px;
  height: 423px;
  margin: 80px auto;
  background: #ffffff;
  box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
/* .printBtn{
  float: right;
  background: #8e9fda;
  color: #fff;
  height: 35px;
  box-sizing: border-box;
  padding: 2px 10px;
  margin-right: 82px;
  margin-bottom: 50px;
  border: 1px solid #d0d0d0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  cursor: pointer;
  margin-top: -35px;
} */
.printBtn:hover {
  background: #041241;
}

.Error {
  padding: 10px;
  background-color: #feeaea;
  border: 1px solid #fadadb;
  margin-bottom: 10px;
  border-radius: 3px;
}
