.btn {
    border: none;
    font-family: 'Lato';
    font-size: inherit;
    color: inherit;
    background: none;
    cursor: pointer;
    padding: 8px 15px 8px 15px;
    display: inline-block;
    background: #3498db;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 50;
    outline: none;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .btn:after {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  /* Pseudo elements for icons */
  .btn:before {
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    position: relative;
    -webkit-font-smoothing: antialiased;
  }
  
  /* Icon separator */
  .btn-sep {
    padding: 8px 15px 8px 15px;
  }
  
  .btn-sep:before {
    background: rgba(255, 255, 255, 0.15);
  }
  
  /* Button 1 */
  .btn-1 {
    background: #3498db;
    color: #fff;
  }

  .btn-1:hover {
    background: #2980b9;
    /* color: #fff; */
  }
  
  .btn-1:active {
    background: #2980b9;
    top: 2px;
  }
  
  .btn-1:before {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 3;
    font-size: 140%;
    width: 60px;
  }

  /* Button Save */
  .btn-save {
    background: #3498db;
    color: rgba(255, 255, 255, 0.705);
    width: 100%;
    margin-bottom: 10px;
  }

  .btn-save:hover {
    background: #2980b9;
    color:#fff;
    /* color:rgba(255, 255, 255, 0.137); */
  }
  
  .btn-save:active {
    background: #2980b9;
    top: 2px;
  }
  
  .btn-save:before {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 3;
    font-size: 140%;
    
  }

  /* Button Reset */
  .btn-reset {
    background: #a51b1b;
    color: rgba(255, 255, 255, 0.705);
    width: 100%;
    margin-bottom: 10px;
  }

  .btn-reset:hover {
    background: #a51b1b;
    color: #fff;
    /* color: rgba(255, 255, 255, 0.137); */
  }

  .btn-reset:active {
    background: #a51b1b;
    top: 2px;
  }
  
  .btn-reset:before {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 3;
    font-size: 140%;
    
  }

  /* Button Signout */
  .btnsignout {
    border: none;
    font-family: 'Lato';
    font-size: inherit;
    color: inherit;
    background: none;
    cursor: pointer;
    padding: 3px 15px 3px 15px;
    display: inline-block;
    background: #3498db;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 50;
    outline: none;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }

  .btnsignout:after {
    content: 'Sign Out';
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  /* Pseudo elements for icons */
  .btnsignout:before {
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    position: relative;
    -webkit-font-smoothing: antialiased;
  }

  .btn-signout {
    background: #a51b1b;
    color: rgba(255, 255, 255, 0.705);
    height: 100%;
    width: 100%;
  }

  .btn-signout:hover {
    background: #a51b1b;
    color: #fff;
  }

  .btn-signout:active {
    background: #a51b1b;
  }
  
  .btn-signout:before {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 3;
    font-size: 40%;
    
  }